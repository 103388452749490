<template>
<div class="address">
  <van-nav-bar
    title="收货地址"
    fixed
    left-arrow
    @click-left="$router.go(-1)"
  />
    
  <van-address-edit
    :area-list="areaList"
    show-postal
    show-set-default
    :address-info="addressInfo"
    :area-columns-placeholder="['请选择', '请选择', '请选择']"
    @save="onSave"
    @delete="onDelete"
  />

</div>
  
</template>
<script>
import { Toast } from 'vant';
import { areaList } from '@vant/area-data';
export default {
  name: "Address",
  data() {
    return {
      loading: false,
      areaList,
      addressInfo: {},
    };
  },
  computed:{
    
  },
  components:{
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData(){
        this.loading = true;
        this.$http.call(this.$http.api.getAddress).then(res=>{
            console.log(res);
            this.loading = false;
            let resp = res.data;
            if(resp.code=='0'){
              if(resp.data){
                this.addressInfo = resp.data;
              }
            }else{
                Toast(resp.msg);
            } 
            
        },function(res){
            console.log('something goes wrong...');
            console.log(res);
        }).then(()=>{
            
        });
    },
    onSave(e) {
      this.$http.call(this.$http.api.postAddress, {
        data: e
      }).then(res=>{
            console.log(res);
            this.loading = false;
            let resp = res.data;
            if(resp.code=='0'){
              Toast('已保存');
            }else{
              Toast(resp.msg);
              if(resp.code == '99'){
                this.$router.push({name: 'login'});
              }
            } 
            
        },function(res){
            console.log('something goes wrong...');
            console.log(res);
        }).then(()=>{
            
        });
    },
    onDelete() {
      Toast('delete');
    }
  },
};
</script>
<style lang="less" scoped>
.address {
    padding: 50px 0;
}
</style>
